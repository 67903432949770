/**
 * 投放列表
 */
import Vue from 'vue'
import { Message,Page,Input,DatePicker,locale,Table,Button,Icon } from 'iview';
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
import { formatUnit } from '@/utils/tools'
import { bannerList,columnsList,launchList,
    getMarketBannerListService,
    getSuperSaleListService,
    getDiscoverListService,
    getBrandWeeklyListService,
    getBrandFeaturedService,
    delMarketBannerService,
    delMarketSuperSaleService,
    delMarketDiscoverService,
    delMarketBrandWeeklyService,
    delMarketFeaturedService
} from '@/service/marketing-service'
import { classification } from "@/service/spu-group-service/index";
import Header from '@/components/Header/header.vue'
import { mapMutations } from "vuex";
const LaunchList = {
    name:'LaunchList',
    components:{
        Header
    },
    data() {
        return {
            formatUnit:formatUnit,
            monthValue:'', //年月值
            weekValue:'', //周值
            bannerList:bannerList,//banner thead 数据集
            launchList:launchList,//投放 thead 数据集
            classification:classification, //分类
            selectAllCancel:[], //全选时的临时存储
            selectAllContentCancel:[], //全选时的临时存储
            bannerLoading:false, //banner loading
            launchLoading:false, //投放 loading
            bannerStatus:false,
            contentStatus:false,
            btnStatus:false,
            columnsList:columnsList, //表头
            bannerArray:[], //banner数据集
            launchArray:[], //投放数据集
            launchParams:{  //选中参数
                bannerValue:1,
                LaunchValue:1,
                type:1,
                checkInput:[],//选择的banner数据列表项
                checkContentInput:[],//选择的数据列表项
            },
        }
    },
    created() {
        this.onMarketBannerList().then()
        this.SET_TYPE_ID('1')
        if(this.launchParams.LaunchValue === 1){
            this.onSuperSaleList().then()
        }
        if(this.launchParams.LaunchValue  === 2){
            this.onDiscoverList().then()
        }
        if(this.launchParams.LaunchValue  === 3){
            this.onBrandWeeklyList().then()
        }
        if(this.launchParams.LaunchValue  === 4){
            this.onBrandFeaturedList().then()
        }
    },
    methods: {
        ...mapMutations('launch',['SET_LAUNCH_ID','SET_LAUNCH_NAME','SET_LAUNCH_TITLE','SET_TYPE_ID']),
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAll(selection) {
            this.selectAllCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.launchParams.checkInput){
                    if(selection[key].id === this.launchParams.checkInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.launchParams.checkInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAllCancel() {
            let selection = this.selectAllCancel
            for(let key in selection){
                for(let index in this.launchParams.checkInput){
                    if(selection[key].id === this.launchParams.checkInput[index]){
                        this.launchParams.checkInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelect(selection,row) {
            let mediaList = this.launchParams.checkInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.launchParams.checkInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectCancel(selection,row) {
            for(let key in this.launchParams.checkInput){
                if(this.launchParams.checkInput[key] === row.id){
                    this.launchParams.checkInput.splice(key,1);
                }
            }
        },
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAll(selection) {
            this.selectAllContentCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.launchParams.checkContentInput){
                    if(selection[key].id === this.launchParams.checkContentInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.launchParams.checkContentInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAllCancel() {
            let selection = this.selectAllContentCancel
            for(let key in selection){
                for(let index in this.launchParams.checkContentInput){
                    if(selection[key].id === this.launchParams.checkContentInput[index]){
                        this.launchParams.checkContentInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelectContent(selection,row) {
            let mediaList = this.launchParams.checkContentInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.launchParams.checkContentInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectContentCancel(selection,row) {
            for(let key in this.launchParams.checkContentInput){
                if(this.launchParams.checkContentInput[key] === row.id){
                    this.launchParams.checkContentInput.splice(key,1);
                }
            }
        },
        /**
         * 删除banner数据
         */
        onBannerDelete() {

            if(this.launchParams.checkInput.length === 0){
                Message.error('Please select')
            }else{
                this.bannerStatus = true;
            }
        },
        /**
         * 删除投放数据
         */
        onContentDelete(){
            if(this.launchParams.checkContentInput.length === 0){
                Message.error('Please select')
            }else{
                this.contentStatus = true;
            }
        },
        /**
         * 删除投放数据确认
         */
        onContentDeleteConfirm() {
            if(this.launchParams.checkContentInput.length === 0){
                Message.error('Please select');
                return;
            }
            if(this.launchParams.LaunchValue === 1){
                this.onDelMarketSuperSale().then()
            }
            if(this.launchParams.LaunchValue  === 2){
                this.onDelMarketDiscover().then()
            }
            if(this.launchParams.LaunchValue  === 3){
                this.onDelMarketBrandWeekly().then()
            }
            if(this.launchParams.LaunchValue  === 4){
                this.onDelMarketFeatured().then()
            }
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 选择年月
         * @param month
         */
        onMonthValue(month){
            this.monthValue = month
        },
        /**
         * 选择周
         * @param week
         */
        onWeekValue(week) {
            this.weekValue = week
        },
        /**
         * 添加数据
         */
        onAddList(){
            if(this.launchParams.bannerValue === 1){
                this.SET_LAUNCH_NAME('Top Banner')
            }
            if(this.launchParams.bannerValue === 2){
                this.SET_LAUNCH_NAME('Mid Banner')
            }
            if(this.launchParams.bannerValue === 3){
                this.SET_LAUNCH_NAME('Tail Banner')
            }
            this.SET_LAUNCH_TITLE('banner')
            this.SET_LAUNCH_ID('')
            this.$router.push('/launch/edit')
        },
        /**
         * 添加数据
         */
        onAddContentList(){
            if(this.launchParams.LaunchValue === 1){
                this.SET_LAUNCH_NAME('Super Sale')
            }
            if(this.launchParams.LaunchValue === 2){
                this.SET_LAUNCH_NAME('Discover')
            }
            if(this.launchParams.LaunchValue === 3){
                this.SET_LAUNCH_NAME('Brand Weekly')
            }
            if(this.launchParams.LaunchValue === 4){
                this.SET_LAUNCH_NAME('Featured')
            }
            this.SET_LAUNCH_TITLE('content')
            this.SET_LAUNCH_ID('')
            this.$router.push('/launch/edit')
        },
        /**
         * 跳转编辑
         * @param id
         */
        onEditBanner(id) {

            if(this.launchParams.bannerValue === 1){
                this.SET_LAUNCH_NAME('Top Banner')
            }
            if(this.launchParams.bannerValue === 2){
                this.SET_LAUNCH_NAME('Mid Banner')
            }
            if(this.launchParams.bannerValue === 3){
                this.SET_LAUNCH_NAME('Tail Banner')
            }
            this.SET_LAUNCH_TITLE('banner')
            this.SET_LAUNCH_ID(id)
            this.$router.push('/launch/edit')
        },
        /**
         * 跳转编辑
         * @param id
         */
        onEditContent(id) {

            if(this.launchParams.LaunchValue === 1){
                this.SET_LAUNCH_NAME('Super Sale')
            }
            if(this.launchParams.LaunchValue === 2){
                this.SET_LAUNCH_NAME('Discover')
            }
            if(this.launchParams.LaunchValue === 3){
                this.SET_LAUNCH_NAME('Brand Weekly')
            }
            if(this.launchParams.LaunchValue === 4){
                this.SET_LAUNCH_NAME('Featured')
            }
            this.SET_LAUNCH_TITLE('content')
            this.SET_LAUNCH_ID(id)
            this.$router.push('/launch/edit')
        },
        /**
         * 选择分类id
         * @param id
         */
        onTypeSelect(id){
            this.launchParams.type = id
            this.SET_TYPE_ID(id)
            if(this.launchParams.LaunchValue === 1){
                this.onSuperSaleList().then()
            }
            if(this.launchParams.LaunchValue  === 2){
                this.onDiscoverList().then()
            }
            if(this.launchParams.LaunchValue  === 3){
                this.onBrandWeeklyList().then()
            }
            if(this.launchParams.LaunchValue  === 4){
                this.onBrandFeaturedList().then()
            }
        },
        /**
         * 选择banner
         * @param value
         */
        onSelectBanner(value) {
            this.launchParams.bannerValue = value
            this.onMarketBannerList().then()
        },
        /**
         * 选择投放
         * @param value
         */
        onSelectLaunch(value) {
            this.launchParams.LaunchValue = value
            this.launchParams.checkContentInput = []
            if(value === 1){
                this.onSuperSaleList().then()
            }
            if(value === 2){
                this.onDiscoverList().then()
            }
            if(value === 3){
                this.onBrandWeeklyList().then()
            }
            if(value === 4){
                this.onBrandFeaturedList().then()
            }
        },
        /**
         * 获取banner数据
         * @returns {Promise<void>}
         */
        async onMarketBannerList() {
            try {
                this.bannerLoading = true
                let params = {
                    'type':this.launchParams.bannerValue,
                }
                const {code,data} = await getMarketBannerListService(params)
                if(code === 1){
                    this.bannerArray = data
                }
                this.bannerLoading = false
            }catch (error){
                this.bannerLoading = false
                console.log(error)
            }
        },
        /**
         * 删除banner数据
         * @returns {Promise<void>}
         */
        async onDelMarketBanner() {
            try {
                if(this.launchParams.checkInput.length === 0){
                    Message.error('Please select');
                    return;
                }
                this.btnStatus = true
                let params = {
                    'ids':this.launchParams.checkInput,
                }
                const {code} = await delMarketBannerService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onMarketBannerList().then()
                    this.launchParams.checkInput = []
                    this.bannerStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
        /**
         * 获取超卖banner数据
         * @returns {Promise<void>}
         */
        async onSuperSaleList() {
            try {
                this.launchLoading = true
                let params = {
                    'cid1':this.launchParams.type,
                    'type':this.launchParams.LaunchValue,
                }
                const {code,data} = await getSuperSaleListService(params)
                if(code === 1){
                    this.launchArray = data
                }
                this.launchLoading = false
            }catch (error){
                this.launchLoading = false
                console.log(error)
            }
        },
        /**
         * 获取discover banner数据
         * @returns {Promise<void>}
         */
        async onDiscoverList() {
            try {
                this.launchLoading = true
                let params = {
                    'cid1':this.launchParams.type,
                    'type':this.launchParams.LaunchValue,
                }
                const {code,data} = await getDiscoverListService(params)
                if(code === 1){
                    this.launchArray = data
                }
                this.launchLoading = false
            }catch (error){
                this.launchLoading = false
                console.log(error)
            }
        },
        /**
         * 获取BrandWeekly banner数据
         * @returns {Promise<void>}
         */
        async onBrandWeeklyList() {
            try {
                this.launchLoading = true
                let params = {
                    'cid1':this.launchParams.type,
                    'type':this.launchParams.LaunchValue,
                }
                const {code,data} = await getBrandWeeklyListService(params)
                if(code === 1){
                    this.launchArray = data
                }
                this.launchLoading = false
            }catch (error){
                this.launchLoading = false
                console.log(error)
            }
        },
        /**
         * 获取Featured数据
         * @returns {Promise<void>}
         */
        async onBrandFeaturedList() {
            try {
                this.launchLoading = true
                let params = {
                    'cid1':this.launchParams.type,
                    'type':this.launchParams.LaunchValue,
                }
                const {code,data} = await getBrandFeaturedService(params)
                if(code === 1){
                    this.launchArray = data
                }
                this.launchLoading = false
            }catch (error){
                this.launchLoading = false
                console.log(error)
            }
        },
        /**
         * 删除super sale数据
         * @returns {Promise<void>}
         */
        async onDelMarketSuperSale() {
            try {
                this.btnStatus = true
                let params = {
                    'ids':this.launchParams.checkContentInput,
                }
                const {code} = await delMarketSuperSaleService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onSuperSaleList().then()
                    this.launchParams.checkContentInput = []
                    this.contentStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
        /**
         * 删除discover数据
         * @returns {Promise<void>}
         */
        async onDelMarketDiscover() {
            try {
                this.btnStatus = true
                let params = {
                    'ids':this.launchParams.checkContentInput,
                }
                const {code} = await delMarketDiscoverService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onDiscoverList().then()
                    this.launchParams.checkContentInput = []
                    this.contentStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
        /**
         * 删除brand weekly数据
         * @returns {Promise<void>}
         */
        async onDelMarketBrandWeekly() {
            try {
                this.btnStatus = true
                let params = {
                    'ids':this.launchParams.checkContentInput,
                }
                const {code} = await delMarketBrandWeeklyService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onBrandWeeklyList().then()
                    this.launchParams.checkContentInput = []
                    this.contentStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
        /**
         * 删除Featured数据
         * @returns {Promise<void>}
         */
        async onDelMarketFeatured() {
            try {
                this.btnStatus = true
                let params = {
                    'ids':this.launchParams.checkContentInput,
                }
                const {code} = await delMarketFeaturedService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onBrandFeaturedList().then()
                    this.launchParams.checkContentInput = []
                    this.contentStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
    },
}
export default LaunchList
