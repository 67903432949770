<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->
        <!--s: Data-->
        <div class="product-prompt">
            <div class="product-data">
                <span class="name">{{ monthValue!==''?monthValue:'MM / YYYY' }}</span>
                <DatePicker type="month" @on-change="onMonthValue" format="MM-yyyy"></DatePicker>
            </div>
<!--            <div class="product-data">-->
<!--                <span class="name">{{ 'Week '+weekValue }}</span>-->
<!--            </div>-->
        </div>
        <!--e: Data-->

        <!--s: Launch -->
        <div class="launch-body">

            <!--s: table-->
            <div class="launch-table">
                <div class="launch-top">
                    <div class="launch-tab">
                        <a href="javascript:" :class="launchParams.bannerValue === banner.value?'active':''" :key="key" v-for="(banner,key) in bannerList" @click="onSelectBanner(banner.value)">{{ banner.label }}</a>
                    </div>
                    <a href="javascript:" class="launch-tab-add" @click="onAddList">
                        <Icon custom="launch-add"></Icon>
                    </a>
                </div>
                <div class="launch-content">
                    <Table max-height="350" :loading="bannerLoading"  :columns="columnsList" :data="bannerArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
                        <template slot="startTime" slot-scope="{ row }">
                            {{ formatUnit(row.startTime) }}
                        </template>
                        <template slot="endTime" slot-scope="{ row }">
                          {{ formatUnit(row.endTime) }}
                        </template>
                        <template slot-scope="{ row }" slot="status">
                            {{ row.status === 1?'Ready':'' }}
                            {{ row.status === 2?'On':'' }}
                            {{ row.status === 3?'Done':'' }}
                        </template>
                        <template slot="btn" slot-scope="{ row }">
                            <a href="javascript:" class="btn" @click="onEditBanner(row.id)">Edit</a>
                        </template>
                    </Table>
                    <div class="launch-btn">
                        <div class="box">
                            <div class="pint" v-if="bannerStatus">
                                <span class="title">Sure delete?</span>
                                <Button style="width:71px" :loading="btnStatus" @click="onDelMarketBanner">Yes</Button>
                                <Button @click="bannerStatus = false">Cancel</Button>
                            </div>
                            <Button @click="onBannerDelete">Delete</Button>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: table-->

            <!--s: table-->
            <div class="launch-table">
                <div class="launch-type">
                    <a href="javascript:" :class="launchParams.type === item.id?'active':''" :key="key" v-for="(item,key) in classification" @click="onTypeSelect(item.id)">{{ item.name }}</a>
                </div>
                <div class="launch-top">
                    <div class="launch-tab">
                        <a href="javascript:" :class="launchParams.LaunchValue === launch.value?'active':''" :key="key" v-for="(launch,key) in launchList" @click="onSelectLaunch(launch.value)">{{ launch.label }}</a>
                    </div>
                    <a href="javascript:" class="launch-tab-add" @click="onAddContentList">
                        <Icon custom="launch-add"></Icon>
                    </a>
                </div>
                <div class="launch-content">
                    <Table max-height="350" :loading="launchLoading"  :columns="columnsList" :data="launchArray" @on-select-all="onSelectContentAll" @on-select-all-cancel="onSelectContentAllCancel" @on-select="onSelectContent" @on-select-cancel="onSelectContentCancel">
                        <template slot="startTime" slot-scope="{ row }">
                          {{ formatUnit(row.startTime) }}
                        </template>
                        <template slot="endTime" slot-scope="{ row }">
                          {{ formatUnit(row.endTime) }}
                        </template>
                        <template slot-scope="{ row }" slot="status">
                            {{ row.status === 1?'Ready':'' }}
                            {{ row.status === 2?'On':'' }}
                            {{ row.status === 3?'Done':'' }}
                        </template>
                        <template slot="btn" slot-scope="{ row }">
                            <a href="javascript:" class="btn" @click="onEditContent(row.id)">Edit</a>
                        </template>
                    </Table>
                    <div class="launch-btn">
                        <div class="box">
                            <div class="pint" v-if="contentStatus">
                                <span class="title">Sure delete?</span>
                                <Button style="width:71px" :loading="btnStatus" @click="onContentDeleteConfirm">Yes</Button>
                                <Button @click="contentStatus = false">Cancel</Button>
                            </div>
                            <Button @click="onContentDelete">Delete</Button>
                        </div>
                    </div>
                  </div>
            </div>
            <!--e: table-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import LaunchList from './list'
export default LaunchList
</script>
<style lang="less">
@import "list";
</style>
